export function useConstants() {
  return {
    QUERY_PARAMS: {
      suppliers: 's',
      categories: 'c',
      curativeTreatments: 't',
      manufacturers: 'm',
      dosageForms: 'd',
      saleDelimitations: 'l',
      typeOfApplications: 'a',
      ingredients: 'i',
      admissionSpecies: 'o',
      vaccines: 'v',
      query: 'q',
    } as const,

    CLINIC_CHAINS: {
      DEFAULT_COLOR: '#954a45',
      DEFAULT_GROUP_COLOR: '#F5A623',
      DEFAULT_GROUP_1_COLOR: '#34A853',
    } as const,

    ORDER_HISTORY_REQUEST_TYPES: {
      ORDER_CANCELLATION: 'orderCancellation',
      INVOICE: 'invoice',
      ORDER_STATUS: 'orderStatus',
    } as const,
  }
}
